<template>
  <section
    class="view-configure-document"
    id="drag-template-background"
    v-loading="documentDataLoading"
  >
    <div
      v-if="checkIsSecureAccess && this.$route?.name == 'document-preview'"
      style="background-color: #ffffff"
    >
      <DocLoading
        :loading="
          documentDataLoading
            ? loadingText
            : 'Please wait getting the things ready for you...'
        "
      ></DocLoading>
    </div>
    <div v-if="!refresh">
      <div v-if="is_template_verification && !is_published">
        <div class="document-corrected-container">
          <img
            :src="require('@/assets/img/icons/document_corrected.svg')"
            style="width: 100px; margin-top: 200px"
          />
          <p class="corrected-heading">
            Document is not published yet - Can't Sign!
          </p>
          <p class="corrected-content">
            The document you’re trying to access is not published. Please come
            back later!
          </p>
        </div>
      </div>
      <div
        v-else-if="
          is_template_verification &&
          is_published &&
          is_requested_users_turn &&
          !checkIsSecureAccess
        "
      >
        <img
          src="@/assets/img/eSigns Logo Black.svg"
          style="margin-top: 20px; margin-left: 10px"
        />
        <div class="document-corrected-container">
          <img
            :src="require('@/assets/img/icons/Document Initiate.svg')"
            style="width: 100px; margin-top: 100px"
          />
          <p class="corrected-heading">
            Your request for "{{
              this.getConfigurableDocumentPublishData.name
            }}" has been initiated.
          </p>
          <p class="corrected-content">
            You will receive notification through email when it is your turn to
            sign.
          </p>
          <p class="correct-content">
            If you have any questions, please reach out to
            {{ getlinksenderEmail }}({{ getlinksendername }} ).
          </p>
          <p class="correct-content">
            To view the status of document, please Sign in to the account.
          </p>
          <div style="margin-top: 2%">
            <el-button
              style="border: 1px solid #f754a2; color: #f754a2"
              @click="gotoSignUp"
              >Sign up</el-button
            >
            <el-button type="danger" @click="gotoSignIn">Sign in</el-button>
          </div>
        </div>
      </div>
      <div v-else-if="!is_template_verification && !is_valid_user">
        <div class="document-corrected-container">
          <img
            :src="require('@/assets/img/icons/document_corrected.svg')"
            style="width: 100px; margin-top: 200px"
          />
          <h1 style="color: red">Forbidden access</h1>
          <h1>You are not allowed to view this document</h1>
        </div>
      </div>
      <div v-else-if="is_formbuilder_verification && !formbuilderisFillable">
        <el-row class="navbar-head">
          <div class="mt-1 ml-1 logo">
            <a href="/dashboard">
              <img
                v-if="companyLogo"
                :src="companyLogo"
                alt="Nimble logo"
                style="max-height: 34px; max-width: 150px"
              />
              <img
                v-else
                src="@/assets/img/logo-white.svg"
                alt="Nimble logo"
                class="img-fluid"
                style="max-height: 34px; max-width: 150px"
              />
            </a>
          </div>
        </el-row>
        <div class="center-card">
          <div class="card">
            <div class="d-flex justify-content-center mr-1">
              <img
                src="@/assets/img/Multiple Submissions.svg"
                height="60"
                width="55"
              />
            </div>
            <h3 class="text-center mt-1">{{ formbuilderErrorMessage }}</h3>
            <div
              class="d-flex justify-content-center"
              v-if="
                formbuilderDetails.customization.show_try_esigns_and_content
              "
            >
              <el-button
                class="btn btn-primary mr-1 m-lr-1"
                type="danger"
                @click="gotoSignIn()"
                >Try eSigns</el-button
              >
            </div>
            <div class="d-flex justify-content-center mt-2">
              <el-button
                type="text"
                @click="showMySubmissions"
                v-if="showUserSubmissions"
                >Show My submissions</el-button
              >
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div
          class="wrapper"
          style="display: flex; flex-direction: column"
          v-if="!checkIsSecureAccess"
        >
          <div class="inner-navbar">
            <div class="d-flex">
              <div class="logo">
                <img
                  v-if="companyLogo"
                  :src="companyLogo"
                  alt="Nimble logo"
                  style="max-height: 30px"
                />
                <img
                  v-else-if="!logoLoading"
                  src="@/assets/img/logo-white.svg"
                  alt="Nimble logo"
                  class="img-fluid"
                />
              </div>
              <div class="flex-fill left-block vue-form ml-2"></div>
              <div class="right-block flex-self-end pe-3 align-items-self">
                <el-tag
                  type="danger"
                  class="expiry-date"
                  v-if="expiration_date"
                >
                  <i class="el-icon-date"></i>
                  <span class="pl-1">{{ expiration_date }}</span>
                </el-tag>
                <el-button
                  class="manage-recipients ml-2"
                  plain
                  v-if="
                    this.getDocumentPreviewData &&
                    this.getDocumentPreviewData.document_users
                  "
                >
                  <el-tag
                    v-for="(user, index) in this.getDocumentPreviewData
                      .document_users"
                    :type="tagType[Math.floor(index % tagType.length)]"
                    :key="user.value"
                    >{{ user | getUserNameAvatar }}</el-tag
                  >
                </el-button>
              </div>
            </div>
          </div>
          <div class="document-preview-block" style="overflow: auto">
            <div class="document-container">
              <div v-loading="documentDataLoading">
                <div class="document-root">
                  <div class="form-builder" ref="formBuilder">
                    <div class="form-holder">
                      <div class="form-image-holder">
                        <img
                          v-if="companyLogo"
                          :src="companyLogo"
                          class="page-image"
                        />
                        <img
                          v-else-if="!logoLoading"
                          src="@/assets/img/logo-white.svg"
                          class="page-image"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="overlay-form" v-if="is_template_verification">
          <el-form class="log-form" @submit.native.prevent>
            <p class="text-white text-center fs-7">
              To view the document, Please enter your email.
            </p>
            <el-form-item
              label="First Name"
              class="mb-1"
              v-if="is_template_email_verified"
            >
              <el-input
                type="text"
                placeholder="Enter first name"
                autocomplete="off"
                v-model="user.first_name"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="Last Name"
              class="mb-1"
              v-if="is_template_email_verified"
            >
              <el-input
                type="text"
                placeholder="Enter last name"
                autocomplete="off"
                v-model="user.last_name"
              ></el-input>
            </el-form-item>
            <el-form-item label="Email" class="mb-1">
              <el-input
                type="text"
                placeholder="Enter mail"
                autocomplete="off"
                v-model="user.email"
                :disabled="accountNameVisible || is_template_email_verified"
              ></el-input>
            </el-form-item>
            <el-form-item v-if="accountNameVisible">
              <div style="display: flex !important">
                <el-select
                  v-model="user.account_name"
                  placeholder="Select Company name"
                  style="width: 90%"
                >
                  <el-option
                    v-for="(item, i) in account_names"
                    :key="i"
                    :value="item._id"
                    :label="
                      item.entity_prime_data[
                        Object.keys(item.entity_prime_data)[0]
                      ]
                    "
                  >
                    {{
                      item.entity_prime_data[
                        Object.keys(item.entity_prime_data)[0]
                      ]
                    }}
                  </el-option>
                </el-select>
                <el-button
                  icon="el-icon-plus"
                  @click="openPrimaryDataDialog"
                ></el-button>
              </div>
            </el-form-item>
            <!-- <p class="error" v-if="accountNameVisible && !account_names.length">
            No companies data added. Please contact sender.
          </p>-->
            <p
              class="error"
              v-if="
                getAuthenticateUserToFillDocumentErrors &&
                getAuthenticateUserToFillDocumentErrors.critical_error
              "
            >
              {{ getAuthenticateUserToFillDocumentErrors.critical_error }}
            </p>
            <p class="error" v-if="checkData">Invalid Link</p>
            <p class="error" v-if="invalid_email">{{ invalid_text }}</p>
            <el-form-item class="mt-4">
              <el-button
                type="primary"
                class="type-2 w-100 fs-6"
                @click="checkContactDetails"
                :loading="verifyLoading"
                :disabled="
                  checkData ||
                  !user.email ||
                  (accountNameVisible && !user.account_name)
                "
                >Submit</el-button
              >
            </el-form-item>
          </el-form>
        </div>
        <div class="overlay-form" v-else-if="is_formbuilder_verification">
          <el-form
            v-if="contactVerfied"
            class="log-form"
            @submit.native.prevent
          >
            <p class="text-white text-center fs-7">
              Please enter the OTP that was sent to your email address:
              {{ formbuilder_user.email }}
            </p>
            <el-form-item class="mb-1" style="color: white"
              >OTP
              <el-input
                type="text"
                placeholder="Enter OTP"
                autocomplete="off"
                v-model="formbuilder_user.passcode"
              ></el-input>
            </el-form-item>
            <p class="error" v-if="getFormBuilderUserVerifyError">
              {{
                getFormBuilderUserVerifyError.critical_error ||
                getFormBuilderUserVerifyError.message
              }}
            </p>
            <el-form-item class="mt-4">
              <el-button
                type="primary"
                class="type-2 w-100 fs-6"
                :loading="verifyLoading"
                @click="verifyUserPasscode"
                >Submit</el-button
              >
            </el-form-item>
            <div style="float: right">
              <el-link
                type="primary"
                :underline="false"
                class="float: right;"
                @click="sentFormbuilderOTP"
                >Resend</el-link
              >
            </div>
          </el-form>
          <el-form class="log-form" v-else @submit.native.prevent>
            <p
              v-if="formbuilder_user.isNewContact"
              class="text-white text-center fs-7"
            >
              It seems you are new. Please provide your basic information for
              future use.
            </p>
            <p class="text-white text-center fs-7" v-else>
              To fill out the {{ getFormbuilderName }} form, Please enter your
              email.
            </p>
            <el-form-item
              class="mb-1"
              v-if="formbuilder_user.isNewContact"
              style="color: white"
              >First Name
              <span class="asterikStyle">*</span>
              <el-input
                type="text"
                placeholder="Enter first name"
                autocomplete="off"
                v-model="formbuilder_user.first_name"
              ></el-input>
            </el-form-item>
            <el-form-item
              class="mb-1"
              v-if="formbuilder_user.isNewContact"
              style="color: white"
              >Last Name
              <span class="asterikStyle">*</span>
              <el-input
                type="text"
                placeholder="Enter last name"
                autocomplete="off"
                v-model="formbuilder_user.last_name"
              ></el-input>
            </el-form-item>
            <el-form-item
              class="mb-1"
              v-if="
                formbuilder_user.isNewContact &&
                formbuilder_user.is_business_entity &&
                formbuilder_user.selecting_existing_data
              "
              style="color: white"
              >{{
                formbuilder_user &&
                formbuilder_user.entityData &&
                formbuilder_user.entityData.name
                  ? formbuilder_user.entityData.name
                  : "Select account"
              }}
              <el-select
                placeholder="Select data"
                v-model="formbuilder_user.account_data_id"
                style="width: 100%"
              >
                <el-option
                  v-for="(data, i) in formbuilder_user.business_entity_data"
                  :key="i + '_entity_data'"
                  :value="data._id"
                  :label="getLabel(data, selectedEntity, i)"
                >
                </el-option>
              </el-select>
              <p class="error" v-if="account_data_error">
                {{ account_data_error }}
              </p>
            </el-form-item>
            <el-form-item class="mb-1" style="color: white"
              >Email
              <span class="asterikStyle">*</span>
              <el-input
                type="text"
                placeholder="Enter mail"
                autocomplete="off"
                v-model="formbuilder_user.email"
                :disabled="formbuilder_user.isNewContact"
                class="my-class"
              ></el-input>
            </el-form-item>
            <p class="error" v-if="invalid_email">{{ invalid_text }}</p>
            <el-form-item class="mt-4">
              <el-button
                type="primary"
                class="type-2 w-100 fs-6"
                @click="checkForContactDetails"
                :loading="verifyLoading"
                >Submit</el-button
              >
            </el-form-item>
          </el-form>
        </div>
        <div :class="checkIsSecureAccess ? '' : 'overlay-form'" v-else>
          <el-form class="log-form" @submit.native.prevent v-if="!checkIsSecureAccess">
            <p class="text-white text-center fs-7">
              To view the document, please enter the password that we have sent
              to your email address.
            </p>
            <el-form-item
              label="Enter Code"
              class="mb-1"
              style="text-fill-color: white"
            >
              <el-input
                type="text"
                placeholder="Enter 6 letters code"
                style="-webkit-text-fill-color: gray"
                autocomplete="off"
                v-model="verificationCode"
              ></el-input>
            </el-form-item>
            <p
              class="error"
              v-if="
                getAuthorizedRepresentativeVerifyPasswordErrors &&
                getAuthorizedRepresentativeVerifyPasswordErrors.password
              "
            >
              {{ getAuthorizedRepresentativeVerifyPasswordErrors.password }}
            </p>

            <p
              class="error"
              v-if="
                getAuthorizedRepresentativeVerifyPasswordErrors &&
                getAuthorizedRepresentativeVerifyPasswordErrors.critical_error
              "
            >
              {{
                getAuthorizedRepresentativeVerifyPasswordErrors.critical_error
              }}
            </p>
            <p
              class="error"
              v-if="
                getAuthorizedRepresentativeVerifyPasswordErrors &&
                getAuthorizedRepresentativeVerifyPasswordErrors.user_id
              "
            >
              Invalid Link
            </p>
            <p
              class="error"
              v-if="
                getAuthorizedRepresentativeVerifyPasswordErrors &&
                getAuthorizedRepresentativeVerifyPasswordErrors.employee_document_id
              "
            >
              Invalid Link
            </p>
            <p class="error" v-if="checkData">Invalid Link</p>
            <el-form-item class="mt-4">
              <el-button
                type="primary"
                class="type-2 w-100 fs-6"
                @click="verifyUser"
                :disabled="checkData || !verificationCode"
                >Submit</el-button
              >
              <p class="text-white mt-2">
                Didn't receive the password?
                <el-link type="primary" @click="resendPassword()">
                  Resend Password</el-link
                >
              </p>
              <!-- <div
              style="float: right; cursor: pointer; text-decoration: underline"
            >
              <a
                type="primary"
                class="type-2 w-100 fs-6"
                @click="verifyUserByPhone"
                >Re-send to mobile</a
              >
            </div> -->
            </el-form-item>
          </el-form>
        </div>
      </div>

      <div class="overlay-form" v-if="authSession">
        <!-- <el-form @keyup.enter.native="verifyUser()" class="log-form"> -->
        <p class="text-white text-center fs-7">
          Sorry! You are logged in with a different account. Please log out from
          the existing session and preview the document.
        </p>

        <!-- </el-form> -->
      </div>
      <!-- New company onboard -->
      <el-dialog
        title="Add new company"
        :visible.sync="addPrimaryDataDialog"
        :before-close="closePrimaryDataDialog"
        class="primary-data-popup"
      >
        <el-scrollbar wrap-style="max-height: 600px;">
          <p>
            Looks like you are new contact. Please fill all fields to continue.
          </p>
          <el-form
            ref="form"
            :model="form"
            v-if="selectedEntity && selectedEntity.primaryFields"
            @submit.native.prevent
          >
            <!-- {{ getEntityDataById.primaryFields }} -->

            <PrimaryDataForm
              :fieldsListData="selectedEntity.primaryFields"
              :form="form"
              :templateRules="[]"
              :isFromDocument="true"
            ></PrimaryDataForm>
          </el-form>
        </el-scrollbar>
        <span slot="footer" class="dialog-footer">
          <el-button
            type="primary"
            @click="addPrimaryDataToEntity('form')"
            size="small"
            :disabled="checkAllfieldsFilled"
            :loading="verifyLoading"
          >
            <i class="el-icon-plus"></i> Submit
          </el-button>
          <el-button @click="closePrimaryDataDialog" size="small"
            >Cancel</el-button
          >
        </span>
      </el-dialog>

      <el-dialog
        custom-class="overlap"
        :visible.sync="formobile"
        width="35%"
        title="Enter Mobile Number"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        style="overflow-y: unset !important; overflow-x: unset !important"
      >
        <div class="vue-phone-validator">
          <VuePhoneNumberInput v-model="phoneno" @update="onUpdate" />
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button
            @click="resendToPhone()"
            type="primary"
            :disabled="!phoneno || !phoneno.length"
            >Submit</el-button
          >
          <el-button @click="formobile = false">Cancel</el-button>
        </span>
      </el-dialog>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import tokenExpiryHelper from "@/mixins/tokenExpiryHelper";
import config from "../../../config/app";
import DocLoading from "../../../views/docLoading.vue";
import { postAPICall } from "@/helpers/httpHelper";
export default {
  name: "EmpDoc-DocumentPreview",
  components: {
    DocLoading,
    PrimaryDataForm: () => import("../../entity/PrimaryDataCollectionForm.vue"),
    VuePhoneNumberInput: () => import("vue-phone-number-input"),
  },
  mixins: [tokenExpiryHelper],
  data() {
    return {
      checkIsSecureAccess: true,
      loadingText: "",
      companyLogo: null,
      documentName: "",
      backgroundImages: [
        "https://devnimblehrms.s3.ap-south-1.amazonaws.com/company-document-backgrounds/60468b2c0a7b7942d5781c36/60468b2c0a7b7942d5781c36-0.png",
      ],
      documentDataLoading: false,
      verificationCode: null,
      verificationEmail: null,
      expiration_date: null,
      tagType: ["success", "info", "", "warning", "danger"],
      is_template_verification: false,
      is_template_email_verified: false,
      is_published: true,
      access_to_all: false,
      invalid_email: false,
      account_data_error: "",
      showContactDetails: false,
      isNewContact: false,
      user: {
        first_name: "",
        last_name: "",
        email: "",
        account_name: "",
      },
      accountNameVisible: false,
      account_names: [],
      templateUsers: [],
      invalid_text: "",
      verifyLoading: false,
      is_requested_users_turn: false,
      is_valid_user: true,
      re: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      nameRe: /[a-zA-Z]{3,}/ || /(?:[A-Za-z].*?\d|\d.*?[A-Za-z]){3,}/,
      authSession: false,
      addPrimaryDataDialog: false,
      form: {},
      selectedEntity: {},
      /* formbuilder data */
      is_formbuilder_verification: false,
      formbuilderisFillable: true,
      showUserSubmissions: false,
      formbuilderErrorMessage: "",
      formbuilder_code: "",
      formbuilder_details_id: "",
      formbuilder_user: {
        email: "",
        first_name: "",
        last_name: "",
        isNewContact: false,
        contact_deatils: {},
        passcode: "",
        account_data_id: "",
        is_business_entity: false,
        selecting_existing_data: false,
        business_entity_data: [],
      },
      formbuilderDetails: {},
      contactVerfied: false,
      submittedCount: 0,
      isAlreadyInitiatedUser: false,
      alreadyInitiatedUserData: null,
      lastSubmittedData: null,
      logoLoading: false,
      formobile: false,
      phoneno: "",
      refresh: true,
    };
  },
  async created() {
    let that = this;
    document.addEventListener("keyup", function (evt) {
      if (evt.keyCode == 13) {
        that.addSubmit(evt);
        evt.preventDefault();
      }
    });
  },
  async mounted() {
    if (this.$route.name == "published-template-preiview") {
      this.checkIsSecureAccess = false;
      this.getPublishedTemplate();
      this.authSession = false;
      this.refresh = false;
    } else if (
      this.$route.name == "FormbuilderGuestAuth" ||
      this.$route.name == "FormbuilderGuestUserAuth" ||
      this.$route.name == "FormbuilderGuestUserPreviewAuth" ||
      this.$route.name == "FormbuilderGuestUserPreviewAuthView"
    ) {
      this.checkIsSecureAccess = false;
      this.getFormbuilderDetails();
    } else {
      this.clearAllStoreData();
      this.getPreviewOfDocument();
      let verified = await this.checkValidLink();
      this.refresh = false;
      if (!verified) {
        this.showLinkExpiredSection = true;

        this.$router.push({
          name: "document-preview-expired",
          params: {
            ...this.$route.params,
          },
        });

        return;
      }
    }
  },
  computed: {
    ...mapGetters("auth", [
      "getAuthenticatedUser",
      "getAuthenticationStatus",
      "getAuthenticationDetails",
      "getAuthorizedRepresentativeVerifyPasswordErrors",
    ]),
    ...mapGetters("documents", [
      "getDocumentPreviewData",
      "getSingleDocumentData",
      "getResendDocumentStatus",
    ]),
    ...mapGetters("configurableDocuments", [
      "getConfigurableDocumentPublishData",
      "getAuthenticateUserToFillDocumentStatus",
      "getAuthenticateUserToFillDocumentData",
      "getAuthenticateUserToFillDocumentErrors",
    ]),
    ...mapGetters("templatesData", [
      "getNewEntityData",
      "getPrimaryEntityDataStatus",
      "getPrimaryEntityDataErrors",
    ]),
    ...mapGetters("contacts", [
      "getCheckContactDataWithEmail",
      "getCheckContactExist",
      "getCreateContactForFormbuilderData",
      "getContact",
    ]),
    ...mapGetters("formBuilders", [
      "getPublicformBuilderDetails",
      "getFormBuilderUserVerifyStatus",
      "getFormBuilderOTPSentStatus",
      "getFormBuilderUserVerifyError",
    ]),
    ...mapGetters("formbuilderData", [
      "getCheckAndFetchFormbuilderError",
      "getCheckAndFetchFormbuilderData",
      "getScheduledFormbuilderData",
    ]),
    ...mapGetters("company", ["getSingleCompanyLogo"]),
    ...mapGetters("anonymousUser", ["getCreateNewAnonymousForm"]),

    getlinksendername() {
      let sender_name = " ";
      let name = this.getConfigurableDocumentPublishData.document_users.find(
        (el) => {
          if (el && el.type == "SENDER") {
            return el;
          }
        }
      );
      if (name) {
        sender_name = name;
      }
      return sender_name.first_name + " " + sender_name.last_name;
    },
    checkAllfieldsFilled() {
      if (
        Object.keys(this.form) &&
        this.selectedEntity.primaryFields &&
        Object.keys(this.form).length >=
          this.selectedEntity.primaryFields.length
      ) {
        let emptyField = this.selectedEntity.primaryFields.find(
          (e) =>
            (e.key && this.form[e.key] == "") || this.form[e.key] == undefined
        );
        if (emptyField) {
          return true;
        }
        return false;
      }
      return true;
    },
    getlinksenderEmail() {
      let sender_name = " ";
      let name = this.getConfigurableDocumentPublishData.document_users.find(
        (el) => {
          if (el && el.type == "SENDER") {
            return el;
          }
        }
      );
      if (name) {
        sender_name = name;
      }
      return sender_name.email;
    },
    checkData() {
      if (this.is_template_verification) {
        if (
          !this.$route.params.configurable_document_id ||
          !this.$route.params.document_id
        ) {
          return true;
        }
        return false;
      } else {
        if (
          !this.$route.params.user_id ||
          !this.$route.params.employee_document_id
        ) {
          return true;
        }
        return false;
      }
    },
    getFormbuilderName() {
      return this.formbuilderDetails && this.formbuilderDetails.name
        ? this.formbuilderDetails.name
        : "";
    },
  },
  methods: {
    getLabel(item, selectedEntity, i) {
      let name =
        item &&
        item.entity_prime_data &&
        Object.keys(item.entity_prime_data) &&
        item.entity_prime_data[Object.keys(item.entity_prime_data)[0]]
          ? item.entity_prime_data[Object.keys(item.entity_prime_data)[0]]
          : "data " + i;
      if (selectedEntity && selectedEntity.primaryFields) {
        let primaryField = selectedEntity.primaryFields[0];
        let key =
          primaryField && primaryField["key"] ? primaryField["key"] : null;
        if (key) {
          if (
            item.entity_prime_data &&
            item.entity_prime_data[key] &&
            item.entity_prime_data[key + "/name"]
          ) {
            name = item.entity_prime_data[key + "/name"]
              ? item.entity_prime_data[key + "/name"]
              : item.entity_prime_data[key];
          } else {
            name =
              item.entityData &&
              primaryField.template_id &&
              item.entityData[primaryField.template_id]
                ? item.entityData[primaryField.template_id][key + "/name"]
                  ? item.entityData[primaryField.template_id][key + "/name"]
                  : item.entityData[primaryField.template_id][key]
                  ? item.entityData[primaryField.template_id][key]
                  : "data" + i
                : "data" + i;
          }
        }
      }
      return name;
    },
    async fetchSingleCompanyLogo(id) {
      this.logoLoading = true;
      await this.$store.dispatch("company/fetchCompanyLogo", id);

      if (this.getSingleCompanyLogo) {
        this.companyLogo = `${config.S3_BUCKET_URL}/${this.getSingleCompanyLogo}`;
      } else {
        this.companyLogo = null;
      }
      this.logoLoading = false;
    },
    onUpdate(payload) {
      if (!payload.isValid) {
        this.error = true;
        // this.errorMsg = `Invalid ${this.data.label}`;
      } else {
        this.error = false;
        this.errorMsg = "";
        this.countryInfo = payload;
        // this.phoneno= this.countryInfo.formattedNumber;
      }
    },

    async verifyUserByPhone() {
      // this.documentDataLoading = true;

      let contactId = this.$route.params.user_id;
      await this.$store.dispatch("contacts/fetchContact", contactId);
      if (this.getContact.phone_number) {
        try {
          let params = {
            document_id: this.$route.params.employee_document_id,
            contact_id: this.$route.params.user_id,
            is_new_user: false,
          };
          await this.$store.dispatch(
            "documents/resendDocumentLinkToContact",
            params
          );
          // this.documentDataLoading = false;

          if (this.getResendDocumentStatus) {
            this.$notify.success({
              title: "Success",
              message: "Document link sent successfully",
            });
          }
        } catch (err) {
          // this.documentDataLoading = false;
          console.log("verifyUserByPhone", err);
        }
      } else {
        this.formobile = true;
      }
    },
    async resendToPhone() {
      let contactId = this.$route.params.user_id;
      await this.$store.dispatch("contacts/fetchContact", contactId);
      let info = this.getContact;
      info.phone_number = this.countryInfo.formattedNumber;
      info.contact_type = this.getContact.contact_type._id;

      try {
        let params = {
          document_id: this.$route.params.employee_document_id,
          contact_id: this.$route.params.user_id,
          is_new_user: false,
        };
        this.documentDataLoading = true;
        await this.$store.dispatch(
          "documents/resendDocumentLinkToContact",
          params
        );
        this.formobile = false;
        this.documentDataLoading = false;
        if (this.getResendDocumentStatus) {
          this.$notify.success({
            title: "Success",
            message: "Document link sent successfully",
          });
        }
      } catch (err) {
        this.documentDataLoading = false;
        console.log("resendToPhone", err);
      }
      let params = {
        document_id: this.$route.params.employee_document_id,
      };
      await this.$store.dispatch("documents/fetchDocumentDataById", params);
      let data = this.getSingleDocumentData.data;
      this.resendDocumentUsers = data.document_users;
      this.ResendDocumentToRecipient = true;
      this.resendEmployeeDocumentId = this.$route.params.employee_document_id;
      let documentId = this.$route.params.employee_document_id;
      console.log("documentId", documentId);
      this.formobile = false;
      this.phoneno = "";
    },

    clearAllStoreData() {
      this.$store.commit(
        "formBuilders/setFormBuilder",
        {},
        {
          root: true,
        }
      );
      this.$store.commit("auth/setAuthenticatedUser", {}, { root: true });
    },
    addSubmit() {
      if (
        this.$route?.name == "document-preview" ||
        this.$route?.name == "published-template-preiview" ||
        this.$route?.name == "FormbuilderGuestAuth" ||
        this.$route?.name == "FormbuilderGuestUserAuth" ||
        this.$route.name == "FormbuilderGuestUserAuthView"
      ) {
        if (this.is_template_verification) {
          this.checkContactDetails();
        } else if (this.is_formbuilder_verification) {
          if (this.contactVerfied) {
            this.verifyUserPasscode();
          } else {
            this.checkForContactDetails();
          }
        } else {
          this.verifyUser();
        }
      }
    },
    /* form builder methods */
    showMySubmissions() {
      if (
        this.formbuilderDetails?.form_builders_owner == "ANONYMOUS" &&
        !this.getAuthenticationStatus
      ) {
        if (
          !this.formbuilder_user?.contact_deatils?._id ||
          !this.formbuilder_user?.passcode?.passcode
        ) {
          this.$message({
            message: "You are not allowed. Invalid access",
            type: "error",
          });
          return;
        }
        let authData = {
          anonymous_user_id: this.formbuilder_user.contact_deatils._id,
          passcode: this.formbuilder_user.passcode.passcode,
          formbuilder_id: this.formbuilderDetails._id,
        };

        let user = {
          ...this.formbuilder_user.contact_deatils,
          ...{
            first_name: "",
            last_name: "",
            email: "",
          },
        };
        this.$store.commit("auth/setAuthenticationDetails", authData, {
          root: true,
        });
        this.$store.commit("auth/setAuthenticationStatus", true, {
          root: true,
        });
        this.$store.commit("auth/setAuthenticatedUser", user, { root: true });
      }
      this.$router.push({
        name: "GuestFormbuilderUserSubmittedDataList",
        params: {
          formbuilder_code: this.$route.params.formbuilder_code,
          formbuilder_details_id: this.$route.params.formbuilder_details_id,
          user_id: this.formbuilder_user.contact_deatils._id,
        },
      });
    },
    async getFormbuilderDetails() {
      this.is_formbuilder_verification = true;
      this.formbuilder_details_id = this.$route.params.formbuilder_details_id;

      await this.$store.dispatch("formBuilders/fetchPublicWorkFlowById", {
        id: this.formbuilder_details_id,
        include_total_submissions_count: true,
        fillable_form_builder: this.formbuilderisFillable,
      });
      if (this.getPublicformBuilderDetails) {
        this.formbuilderDetails = this.getPublicformBuilderDetails;
        await this.fetchSingleCompanyLogo(this.formbuilderDetails.company);
      }
      await this.checkFormbuilderAccess();
      if (this.formbuilderDetails?.form_builders_owner == "ANONYMOUS") {
        if (this.formbuilderisFillable) {
          if (this.formbuilderDetails?.auth_user?.user) {
            this.submittedCount =
              this.formbuilderDetails.auth_user.submittedCount;
            this.authenticateAnonymousUser();
          } else {
            await this.fetchContactDetails();
            await this.authenticateAnonymousUser();
          }
        } else {
          this.refresh = false;
        }
        return;
      } else {
        this.refresh = false;
      }
      if (
        (this.$route.name == "FormbuilderGuestUserAuth" ||
          this.$route.name == "FormbuilderGuestUserPreviewAuth" ||
          this.$route.name == "FormbuilderGuestUserPreviewAuthView") &&
        this.$route.params.user_id
      ) {
        this.fetchContactDetails();
      }
      if (this.$route.query.email) {
        this.formbuilder_user.email = this.$route.query.email;
        if (this.$route.query.user_id && this.$route.query.schedule_id) {
          await this.fetchScheduledFormbuilderData({
            user_id: this.$route.query.user_id,
            schedule_id: this.$route.query.schedule_id,
          });
        } else {
          this.checkForContactDetails();
        }
      }
    },
    async authenticateAnonymousUser() {
      this.loading = true;
      if (
        !this.formbuilderDetails.settings.frequency &&
        !this.isAlreadyInitiatedUser &&
        this.submittedCount > 0
      ) {
        if (this.formbuilderDetails.settings.view_responses) {
          this.showUserSubmissions = true;
        }
        if (this.lastSubmittedData && this.lastSubmittedData._id) {
          await this.openAnonymousForm(this.lastSubmittedData._id);
        } else {
          this.formbuilderisFillable = false;
          this.formbuilderErrorMessage =
            "You have already submitted a form. Multiple submissions are not allowed.";
          this.refresh = false;
        }
      } else if (
        this.submittedCount &&
        !this.isAlreadyInitiatedUser &&
        this.formbuilderDetails &&
        this.formbuilderDetails.settings &&
        this.formbuilderDetails.settings.limit_responses &&
        this.formbuilderDetails.settings.no_responses_limited <=
          this.submittedCount
      ) {
        if (this.lastSubmittedData && this.lastSubmittedData._id) {
          this.openAnonymousForm();
        } else {
          if (this.formbuilderDetails.settings.view_responses) {
            this.showUserSubmissions = true;
          }
          this.formbuilderisFillable = false;
          this.formbuilderErrorMessage =
            "You have already reached max submissions for this form builder";
          this.refresh = false;
        }
      } else {
        await this.openAnonymousForm();
      }
      this.loading = true;
    },
    async openAnonymousForm(dataId) {
      let authData;
      if (this.formbuilderDetails?.auth_user) {
        authData = this.formbuilderDetails.auth_user;
      } else {
        await this.$store.dispatch(
          "anonymousUser/fetchCreateNewAnonymousForm",
          {
            company_id: this.formbuilderDetails.company,
            formbuilder_id: this.formbuilderDetails._id,
          }
        );
        authData = this.getCreateNewAnonymousForm;
      }
      this.checkAllowAnonymousUser(authData, dataId);
    },
    checkAllowAnonymousUser(createNewAnonymousForm, dataId) {
      if (createNewAnonymousForm?.user && createNewAnonymousForm?.formbuilder) {
        let authData = {
          anonymous_user_id: createNewAnonymousForm.user._id,
          passcode: createNewAnonymousForm.formbuilder.passcode,
          formbuilder_id: this.formbuilderDetails._id,
        };

        let user = {
          ...createNewAnonymousForm.user,
          ...{
            first_name: "",
            last_name: "",
            email: "",
          },
        };

        this.$store.commit("auth/setAuthenticationDetails", authData, {
          root: true,
        });
        this.$store.commit("auth/setAuthenticationStatus", true, {
          root: true,
        });
        this.$store.commit("auth/setAuthenticatedUser", user, { root: true });

        let query = this.$route.query || {};
        if (dataId) {
          query["formbuilder_data_id"] = dataId;
        }

        this.$router.push({
          name: "GuestFormbuilderExecuteStep",
          params: {
            formbuilder_code: this.formbuilderDetails.code,
            formbuilder_details_id: this.formbuilderDetails._id,
            template_code: this.formbuilderDetails.steps[0].slug,
          },
          query: {
            ...query,
            ...{
              user_id: createNewAnonymousForm.user._id,
            },
          },
        });
      } else {
        this.formbuilderisFillable = false;
        this.formbuilderErrorMessage = "Something went wrong. Please try again";
      }
    },
    async fetchScheduledFormbuilderData(params) {
      this.verifyLoading = true;
      await this.$store.dispatch(
        "formbuilderData/getFormbuilderDataByScheduleIdForContact",
        params
      );
      if (this.getScheduledFormbuilderData?.already_submitted) {
        if (this.getScheduledFormbuilderData?.pending_formbuilder) {
          this.isAlreadyInitiatedUser = true;
          this.alreadyInitiatedUserData = {
            _id: this.getScheduledFormbuilderData.pending_formbuilder,
          };
          this.checkForContactDetails();
        } else {
          this.formbuilderisFillable = false;
          this.formbuilderErrorMessage =
            "You have already submitted for this schedule.";
        }
      } else {
        this.verifyLoading = false;
        this.checkForContactDetails();
      }
    },
    async fetchContactDetails() {
      this.documentDataLoading = true;
      await this.$store.dispatch(
        "formbuilderData/checkAndFetchFormbuilderDataForContact",
        {
          contact: this.$route.params.user_id,
          formbuilder_id: this.formbuilder_details_id,
        }
      );
      if (
        this.getCheckAndFetchFormbuilderData &&
        this.getCheckAndFetchFormbuilderData.contactData
      ) {
        this.documentDataLoading = false;
        this.formbuilder_user.isNewContact =
          this.getCheckAndFetchFormbuilderData.isNewContact;
        this.submittedCount =
          this.getCheckAndFetchFormbuilderData.submittedCount;
        if (
          !this.formbuilder_user.isNewContact &&
          this.getCheckAndFetchFormbuilderData.contactData &&
          this.formbuilderDetails?.form_builders_owner !== "ANONYMOUS"
        ) {
          this.formbuilder_user.contact_deatils =
            this.getCheckAndFetchFormbuilderData.contactData;
          this.checkFormbuilderSecure();
        }
        if (
          !this.formbuilder_user.isNewContact &&
          this.getCheckAndFetchFormbuilderData.contactData &&
          this.formbuilderDetails?.form_builders_owner == "ANONYMOUS"
        ) {
          this.formbuilder_user.contact_deatils =
            this.getCheckAndFetchFormbuilderData.contactData;
          this.formbuilder_user.passcode =
            this.getCheckAndFetchFormbuilderData.passcode;
        }
        if (
          (this.$route.name == "FormbuilderGuestUserPreviewAuth" ||
            this.$route.name == "FormbuilderGuestUserPreviewAuthView") &&
          this.$route.params.formbuilder_data_id
        ) {
          this.isAlreadyInitiatedUser = true;
          this.alreadyInitiatedUserData = {
            _id: this.$route.params.formbuilder_data_id,
          };
        } else if (
          this.getCheckAndFetchFormbuilderData.formbuilderData &&
          this.getCheckAndFetchFormbuilderData.formbuilderData._id
        ) {
          this.isAlreadyInitiatedUser = true;
          this.alreadyInitiatedUserData =
            this.getCheckAndFetchFormbuilderData.formbuilderData;
        }
      } else {
        this.documentDataLoading = false;
        let message = this.getCheckAndFetchFormbuilderError;
        this.$message({
          message:
            message && message.critical_error
              ? message.critical_error
              : "You are not allowed. Invalid access",
          type: "error",
        });
        this.formbuilderisFillable = false;
        this.formbuilderErrorMessage = `Form can't be accessed. Invalid link.`;
      }
    },
    checkFormbuilderAccess() {
      if (this.formbuilderDetails && this.formbuilderDetails.settings) {
        if (this.formbuilderDetails.settings.is_time_bond) {
          let todayData = moment();
          let startData = moment(
            this.formbuilderDetails.settings.formbuilder_start_time
          );
          let endDate = moment(
            this.formbuilderDetails.settings.formbuilder_end_time
          );
          if (todayData < startData) {
            this.formbuilderisFillable = false;
            this.formbuilderErrorMessage = `Form will be available from ${this.$moment(
              this.formbuilderDetails.settings.formbuilder_start_time
            ).format("MM-DD-YYYY HH:mm A")}`;
          } else if (todayData > endDate) {
            this.formbuilderisFillable = false;
            this.formbuilderErrorMessage = `The form expired on ${this.$moment(
              this.formbuilderDetails.settings.formbuilder_end_time
            ).format("MM-DD-YYYY HH:mm A")}`;
          }
        }
        if (this.formbuilderDetails.settings.limit_total_submissions) {
          if (
            this.formbuilderDetails.settings.no_total_submissions_limited <=
            this.formbuilderDetails.total_submissions_count
          ) {
            this.formbuilderisFillable = false;
            this.formbuilderErrorMessage =
              "This form builder already reached max submissions";
          }
        }
      }
    },

    async checkForContactDetails() {
      this.formbuilder_user.email = this.formbuilder_user.email.trim();
      let re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!re.test(this.formbuilder_user.email)) {
        this.invalid_email = true;
        this.invalid_text = "Invalid Email";
        return;
      }
      this.invalid_email = false;
      this.verifyLoading = true;
      if (this.formbuilder_user.isNewContact) {
        if (
          this.selectedEntity?.entity_type == "BUSINESS" &&
          !this.formbuilder_user.account_data_id
        ) {
          if (!this.formbuilder_user.selecting_existing_data) {
            this.openPrimaryDataDialog();
          } else {
            this.account_data_error =
              "please select " + this.selectedEntity.name;
          }
          this.invalid_email = false;
          this.verifyLoading = false;
          return;
        }
        let re = /[a-zA-Z]{1,}/ || /(?:[A-Za-z].*?\d|\d.*?[A-Za-z]){1,}/;
        if (
          !re.test(this.formbuilder_user.first_name) ||
          !this.formbuilder_user.last_name
        ) {
          this.invalid_email = true;
          this.invalid_text = "Invalid Name";
          this.verifyLoading = false;
          return;
        }
        await this.$store.dispatch("contacts/createContactForFormbuilder", {
          ...{
            first_name: this.formbuilder_user.first_name,
            last_name: this.formbuilder_user.last_name,
            email: this.formbuilder_user.email.toLowerCase(),
            company_id: this.formbuilderDetails.company,
            created_by: this.formbuilderDetails.created_by,
            user_id: this.formbuilderDetails.created_by,
          },
          ...(this.formbuilder_user &&
            this.formbuilder_user.contact_deatils &&
            this.formbuilder_user.contact_deatils._id && {
              contact_id: this.formbuilder_user.contact_deatils._id,
            }),
          ...(this.formbuilderDetails &&
            this.formbuilderDetails.form_builders_owner == "ENTITY" &&
            this.formbuilderDetails.entity_id && {
              contact_type: this.formbuilderDetails.entity_id,
              entity_id: this.formbuilderDetails.entity_id,
            }),
          ...(this.selectedEntity?.entity_type == "BUSINESS" && {
            account_data_id: this.formbuilder_user.account_data_id,
          }),
        });
        if (this.getCreateContactForFormbuilderData) {
          this.formbuilder_user.contact_deatils =
            this.getCreateContactForFormbuilderData;
          await this.checkFormbuilderSecure();
        }
      } else {
        await this.$store.dispatch("contacts/checkContactEmail", {
          email: this.formbuilder_user.email.toLowerCase(),
          company_id: this.formbuilderDetails.company,
          include_formbuilder_data_count: true,
          formbuilder_id: this.formbuilderDetails._id,
          owner: this.formbuilderDetails.form_builders_owner,
          entity_id: this.formbuilderDetails.entity_id,
        });
        if (this.getCheckContactExist) {
          if (this.getCheckContactExist.success) {
            this.formbuilder_user.selecting_existing_data =
              this.getCheckContactExist.selectingExistingData;
            this.formbuilder_user.is_business_entity =
              this.getCheckContactExist.isBusinessEntity;
            this.formbuilder_user.business_entity_data =
              this.getCheckContactExist.businessEntityData;
            this.formbuilder_user.isNewContact =
              this.getCheckContactExist.isNewContact;
            this.submittedCount = this.getCheckContactExist.submittedCount;
            this.lastSubmittedData =
              this.getCheckContactExist.lastSubmittedData;
            if (this.getCheckContactExist?.entityData) {
              this.selectedEntity = this.getCheckContactExist.entityData;
              this.verifyLoading = false;
            }
            if (!this.getCheckContactExist.validContact) {
              this.invalid_email = true;
              this.invalid_text = this.getCheckContactExist.message
                ? this.getCheckContactExist.message
                : "Form builder is restricted. Can't access.";
              this.verifyLoading = false;
            } else if (
              this.formbuilder_user.isNewContact &&
              this.getCheckContactExist.contactData?.first_name
            ) {
              this.formbuilder_user.contact_deatils =
                this.getCheckContactExist.contactData;
              this.formbuilder_user.first_name =
                this.getCheckContactExist.contactData.first_name;
              this.formbuilder_user.last_name =
                this.getCheckContactExist.contactData.last_name;
              this.formbuilder_user.email =
                this.getCheckContactExist.contactData.email.toLowerCase();
              this.checkForContactDetails();
              this.verifyLoading = false;
            } else if (
              !this.formbuilder_user.isNewContact &&
              this.getCheckContactExist.contactData
            ) {
              this.formbuilder_user.contact_deatils =
                this.getCheckContactExist.contactData;
              await this.checkFormbuilderSecure();
              this.verifyLoading = false;
            }
            this.verifyLoading = false;
          } else {
            this.invalid_email = true;
            this.invalid_text = this.getCheckContactExist.message
              ? this.getCheckContactExist.message
              : "Form builder is restricted. Can't access.";
            this.verifyLoading = false;
          }
        } else {
          this.invalid_email = true;
          this.invalid_text =
            "Something went wrong. Please refresh the screen.";
          this.verifyLoading = false;
        }
      }
    },
    checkFormbuilderSecure() {
      if (this.formbuilderDetails) {
        if (
          this.formbuilderDetails.settings &&
          this.formbuilderDetails.settings.secure_access
        ) {
          if (this.$route.query.application_user) {
            this.createPasscodeWithoutSecureAccess();
          } else {
            this.sentFormbuilderOTP();
          }
        } else {
          this.createPasscodeWithoutSecureAccess();
        }
      } else {
        this.$message({
          message: "Something went wrong. Please refresh the page",
          type: "error",
        });
      }
    },
    async createPasscodeWithoutSecureAccess() {
      await this.$store.dispatch("formBuilders/sentFormBuilderOTPToContact", {
        contact_id: this.formbuilder_user.contact_deatils._id,
        formbuilder_id: this.formbuilderDetails._id,
        company_id: this.formbuilderDetails.company,
        secure_access: false,
        is_contact: this.$route.query.application_user || false,
      });
      if (this.getFormBuilderOTPSentStatus) {
        this.authenticateFormbuilderUser();
      } else {
        this.$message({
          message: "Error while authenticating. Please refresh the page",
          type: "error",
        });
      }
    },
    authenticateFormbuilderUser() {
      let user = this.formbuilder_user.contact_deatils;
      if (this.formbuilderDetails?.entity_id) {
        let currentEntity = (user.contact_types || []).find(
          (e) => e.contact_type == this.formbuilderDetails?.entity_id
        );
        if (currentEntity && currentEntity.account_data_id) {
          user.account_data_id = currentEntity.account_data_id;
          if (currentEntity?.contact_type?._id) {
            user.contact_type = currentEntity.contact_type._id;
          }
        }
      }
      this.$store.commit("auth/setAuthenticatedUser", user, {
        root: true,
      });
      let query = this.$route.query || {};
      if (
        !this.formbuilderDetails.settings.frequency &&
        !this.isAlreadyInitiatedUser &&
        this.submittedCount > 0
      ) {
        if (this.formbuilderDetails.settings.view_responses) {
          this.showUserSubmissions = true;
        }
        if (this.lastSubmittedData && this.lastSubmittedData._id) {
          this.$router.push({
            name: "GuestFormbuilderExecuteUpdateStep",
            params: {
              formbuilder_code: this.formbuilderDetails.code,
              template_code: this.formbuilderDetails.steps[0].slug,
              formbuilder_details_id: this.formbuilderDetails._id,
              formbuilder_data_id: this.lastSubmittedData._id,
            },
            query: {
              ...query,
              ...{ user_id: this.formbuilder_user.contact_deatils._id },
            },
          });
        } else {
          this.formbuilderisFillable = false;
          this.formbuilderErrorMessage =
            "You have already submitted a form. Multiple submissions are not allowed.";
        }
      } else if (
        this.submittedCount &&
        !this.isAlreadyInitiatedUser &&
        this.formbuilderDetails &&
        this.formbuilderDetails.settings &&
        this.formbuilderDetails.settings.limit_responses &&
        this.formbuilderDetails.settings.no_responses_limited <=
          this.submittedCount
      ) {
        if (this.lastSubmittedData && this.lastSubmittedData._id) {
          this.$router.push({
            name: "GuestFormbuilderExecuteUpdateStep",
            params: {
              formbuilder_code: this.formbuilderDetails.code,
              template_code: this.formbuilderDetails.steps[0].slug,
              formbuilder_details_id: this.formbuilderDetails._id,
              formbuilder_data_id: this.lastSubmittedData._id,
            },
            query: {
              ...query,
              ...{ user_id: this.formbuilder_user.contact_deatils._id },
            },
          });
        } else {
          if (this.formbuilderDetails.settings.view_responses) {
            this.showUserSubmissions = true;
          }
          this.formbuilderisFillable = false;
          this.formbuilderErrorMessage =
            "You have already reached max submissions for this form builder";
        }
      } else {
        //GuestFormbuilderExecuteUpdateStep
        if (this.isAlreadyInitiatedUser && this.alreadyInitiatedUserData) {
          if (this.$route.name == "FormbuilderGuestUserPreviewAuthView") {
            this.$router.push({
              name: "GuestFormbuilderView",
              params: {
                formbuilder_code: this.formbuilderDetails.code,
                template_code: this.formbuilderDetails.steps[0].slug,
                formbuilder_details_id: this.formbuilderDetails._id,
                formbuilder_data_id: this.alreadyInitiatedUserData._id,
              },
              query: {
                ...query,
                ...{ user_id: this.formbuilder_user.contact_deatils._id },
              },
            });
          } else {
            this.$router.push({
              name:
                this.$route.name == "FormbuilderGuestUserPreviewAuth" &&
                this.$route.params.formbuilder_data_id
                  ? "GuestFormbuilderExecuteViewStep"
                  : "GuestFormbuilderExecuteUpdateStep",
              params: {
                formbuilder_code: this.formbuilderDetails.code,
                template_code: this.formbuilderDetails.steps[0].slug,
                formbuilder_details_id: this.formbuilderDetails._id,
                formbuilder_data_id: this.alreadyInitiatedUserData._id,
              },
              query: {
                ...query,
                ...{ user_id: this.formbuilder_user.contact_deatils._id },
              },
            });
          }
        } else {
          this.$router
            .push({
              name: "GuestFormbuilderExecuteStep",
              params: {
                formbuilder_code: this.formbuilderDetails.code,
                formbuilder_details_id: this.formbuilderDetails._id,
                template_code: this.formbuilderDetails.steps[0].slug,
              },
              query: {
                ...query,
                ...{ user_id: this.formbuilder_user.contact_deatils._id },
              },
            })
            .catch(() => {});
        }
      }
    },
    async sentFormbuilderOTP(isResend = false) {
      this.verifyLoading = true;
      await this.$store.dispatch("formBuilders/sentFormBuilderOTPToContact", {
        contact_id: this.formbuilder_user.contact_deatils._id,
        formbuilder_id: this.formbuilderDetails._id,
        company_id: this.formbuilderDetails.company,
        secure_access: true,
      });
      if (this.getFormBuilderOTPSentStatus) {
        const successMessage = isResend
          ? "OTP Resent Successfully"
          : "OTP Sent Successfully";
        this.$message({
          message: successMessage,
          type: "success",
        });
        this.contactVerfied = true;
        this.verifyLoading = false;
      } else {
        this.verifyLoading = false;
        this.$message({
          message: "Error while sending OTP. Please refresh the page",
          type: "error",
        });
      }
    },
    async verifyUserPasscode() {
      await this.$store.dispatch("formBuilders/verifyFormbuilderUser", {
        passcode: this.formbuilder_user.passcode,
        contact: this.formbuilder_user.contact_deatils._id,
        formbuilder_details_id: this.formbuilderDetails._id,
        company_id: this.formbuilderDetails.company,
      });
      if (this.getFormBuilderUserVerifyStatus) {
        this.authenticateFormbuilderUser();
      }
    },
    /* form builder methods end*/
    openPrimaryDataDialog() {
      this.addPrimaryDataDialog = true;
    },
    closePrimaryDataDialog() {
      this.addPrimaryDataDialog = false;
    },
    async addPrimaryDataToEntity() {
      try {
        this.verifyLoading = true;
        if (this.form) {
          let data = {
            primaryFields: this.selectedEntity.primaryFields,
            formData: this.form,
            templates: this.selectedEntity.templates,
            entity_id: this.selectedEntity._id,
            user_id: this.selectedEntity.created_by,
            company_id: this.selectedEntity.company_id,
            doc_template_id: this.$route.params.configurable_document_id,
          };
          await this.$store.dispatch(
            "templatesData/createEntityPrimaryData",
            data
          );
          if (this.getPrimaryEntityDataStatus) {
            this.addPrimaryDataDialog = false;
            if (this.getNewEntityData && this.getNewEntityData._id) {
              if (this.is_template_verification) {
                this.user.account_name = this.getNewEntityData._id;
                this.verifyUserEmail();
              } else if (this.is_formbuilder_verification) {
                this.formbuilder_user.account_data_id =
                  this.getNewEntityData._id;
                this.checkForContactDetails();
              }
            }
            this.$notify.success({
              title: "Success",
              message: "Primary Data saved successfully",
            });
          } else {
            this.verifyLoading = false;
            let msg = "Error at saving data";
            if (
              this.getPrimaryEntityDataErrors &&
              this.getPrimaryEntityDataErrors.message
            ) {
              msg = this.getPrimaryEntityDataErrors.message;
            }
            this.$notify.error({
              title: "Error",
              message: msg,
            });
          }
        } else {
          this.verifyLoading = false;
          this.$notify.error({
            title: "Error",
            message: "Please fill all the fields in primary data",
          });
        }
      } catch (err) {
        console.log("addPrimaryDataToEntity", err);
      }
    },
    async checkContactDetails() {
      if (this.re.test(this.user.email)) {
        if (this.is_template_email_verified) {
          if (this.getCheckContactDataWithEmail.is_business) {
            this.user.account_name = "";
            this.account_names = this.getCheckContactDataWithEmail.data;
            this.selectedEntity = this.getCheckContactDataWithEmail.entity;
            // this.accountNameVisible = true;
            this.openPrimaryDataDialog();
          } else {
            this.verifyUserEmail();
          }
        } else if (!this.accountNameVisible && !this.user.account_name) {
          this.verifyLoading = true;
          const user = this.templateUsers.find(
            (user) => user.type != "SENDER" && !user.constant_user
          );
          await this.$store.dispatch("contacts/checkContactEmailForGetLink", {
            email: this.user.email.toLowerCase(),
            entity: user.contact_type,
            company_id: this.getConfigurableDocumentPublishData.company_id,
            user_id: this.getConfigurableDocumentPublishData.created_by,
            access_to_all: this.access_to_all,
          });
          if (
            this.getCheckContactDataWithEmail &&
            this.getCheckContactDataWithEmail.allow
          ) {
            this.verifyLoading = false;
            if (this.getCheckContactDataWithEmail?.contact_info?.first_name) {
              this.user.first_name =
                this.getCheckContactDataWithEmail?.contact_info?.first_name;
              this.user.last_name =
                this.getCheckContactDataWithEmail?.contact_info?.last_name;
              if (this.getCheckContactDataWithEmail.is_business) {
                this.user.account_name = "";
                this.account_names = this.getCheckContactDataWithEmail.data;
                this.selectedEntity = this.getCheckContactDataWithEmail.entity;
                // this.accountNameVisible = true;
                this.openPrimaryDataDialog();
              } else {
                this.verifyUserEmail();
              }
            } else {
              this.is_template_email_verified = true;
            }
          } else {
            this.verifyLoading = false;
          }
        } else if (this.accountNameVisible && !this.user.account_name) {
          this.invalid_text = "Plese select company name";
          this.invalid_email = true;
        } else if (this.accountNameVisible && this.user.account_name) {
          this.verifyUserEmail();
        }
      } else {
        this.invalid_text = "Invalid Email";
        this.invalid_email = true;
      }
    },
    gotoSignIn() {
      // this.$router.go(-1);
      this.$router.push({
        path: "/",
      });
    },
    gotoSignUp() {
      // this.$router.go(-1);
      this.$router.push({
        path: "/signup",
      });
    },

    async createDocumentFromTemplate() {
      try {
        await this.$store.dispatch("configurableDocuments/");
      } catch (err) {
        console.log("createDocumentFromTemplate", err);
      }
    },
    getTimeZone() {
      let date1 = new Date();
      var sign = date1.getTimezoneOffset() > 0 ? "-" : "+";
      var offset = Math.abs(date1.getTimezoneOffset());
      var hours =
        Math.floor(offset / 60) < 10
          ? "0" + Math.floor(offset / 60)
          : Math.floor(offset / 60);
      var minutes = offset % 60 < 10 ? "0" + (offset % 60) : offset % 60;
      let timezoneoffset = sign + hours + ":" + minutes;
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      return timezone + ", UTC" + timezoneoffset;
    },

    async checkValidLink() {
      let token = this.$route.query.token;

      if (token) {
        let value = await this.checkTokenValidation(
          this.$route.params.employee_document_id,
          token
        );
        return value;
      }

      return false;
    },
    async verifyUserEmail() {
      try {
        let re =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (re.test(this.user.email)) {
          let re = /[a-zA-Z]{1,}/ || /(?:[A-Za-z].*?\d|\d.*?[A-Za-z]){1,}/;
          if (re.test(this.user.first_name) && re.test(this.user.last_name)) {
            this.invalid_email = false;
            let contact = this.templateUsers.find(
              (user) => !user.constant_user && user.contact_type
            );
            this.documentDataLoading = true;
            let timeZone = this.getTimeZone();
            await this.$store.dispatch(
              "configurableDocuments/authenticateUserToFillDocument",
              {
                configurable_document_id:
                  this.$route.params.configurable_document_id,
                first_name: this.user.first_name,
                last_name: this.user.last_name,
                email: this.user.email.toLowerCase(),
                account_name: this.user.account_name,
                sender_time_zone: timeZone,
                contact_type: contact.contact_type,
              }
            );
            if (this.getAuthenticateUserToFillDocumentStatus) {
              this.showContactDetails = true;
              if (
                this.getAuthenticateUserToFillDocumentData &&
                this.getAuthenticateUserToFillDocumentData
                  .is_requested_user_turn &&
                this.getAuthenticateUserToFillDocumentData
                  .employee_document_id &&
                this.getAuthenticateUserToFillDocumentData.contact_id &&
                this.getAuthenticateUserToFillDocumentData.secure_access
              ) {
                this.documentDataLoading = false;
                this.$notify.success({
                  title: "Success",
                  message: "Document initiated successfully",
                });
                this.is_template_verification = false;

                this.$router.push({
                  name: "document-preview",
                  params: {
                    employee_document_id:
                      this.getAuthenticateUserToFillDocumentData
                        .employee_document_id,
                    user_id:
                      this.getAuthenticateUserToFillDocumentData.contact_id,
                  },
                });
                // window.location.reload();
              } else if (
                this.getAuthenticateUserToFillDocumentData &&
                this.getAuthenticateUserToFillDocumentData
                  .is_requested_user_turn &&
                this.getAuthenticateUserToFillDocumentData
                  .employee_document_id &&
                !this.getAuthenticateUserToFillDocumentData.secure_access
              ) {
                this.$notify.success({
                  title: "Success",
                  message: "Document initiated successfully",
                });
                this.documentDataLoading = false;
                this.$router.push({
                  name: "employee-documents-custom-document",
                  params: {
                    employee_document_id:
                      this.getAuthenticateUserToFillDocumentData
                        .employee_document_id,
                  },
                });
              } else if (
                this.getAuthenticateUserToFillDocumentData &&
                !this.getAuthenticateUserToFillDocumentData
                  .is_requested_user_turn
              ) {
                this.is_requested_users_turn = true;
                this.$notify.success({
                  title: "Success",
                  message: "Document initiated successfully.",
                });
                this.documentDataLoading = false;
              }
            } else if (this.getAuthenticateUserToFillDocumentErrors) {
              this.documentDataLoading = false;
            }
          } else {
            this.invalid_text = "Invalid Name";
            this.invalid_email = true;
          }
        } else {
          this.invalid_text = "Invalid Email";
          this.invalid_email = true;
        }
      } catch (err) {
        console.log("verifyUserEmail", err);
      }
    },
    async getPublishedTemplate() {
      try {
        this.is_template_verification = true;
        await this.$store.dispatch(
          "configurableDocuments/checkConfigurableDocumentPublished",
          {
            configurable_document_id:
              this.$route.params.configurable_document_id,
          }
        );
        if (this.getConfigurableDocumentPublishData) {
          this.is_published =
            this.getConfigurableDocumentPublishData.is_published;
          this.access_to_all =
            this.getConfigurableDocumentPublishData.access_to_all;
          this.templateUsers =
            this.getConfigurableDocumentPublishData.document_users;
          await this.fetchSingleCompanyLogo(
            this.getConfigurableDocumentPublishData.company_id
          );
        }
      } catch (err) {
        console.log("getPublishedTemplate", err);
      }
    },
    async getPreviewOfDocument() {
      try {
        this.documentDataLoading = true;
        this.loadingText = "Fetching User Details...";
        let id = this.$route.params.employee_document_id;
        let contact_id = this.$route.params.user_id;
        let params = {
          id: id,
          user_id: contact_id,
        };
        await this.$store.dispatch("documents/getDocumentPreviewData", params);
        if (this.getDocumentPreviewData) {
          if (
            this.getDocumentPreviewData &&
            this.getDocumentPreviewData.document_settings &&
            this.getDocumentPreviewData.document_settings.secure_e_sign &&
            this.getDocumentPreviewData.document_settings.secure_e_sign
              .document_access_with_password === false
          ) {
            this.checkIsSecureAccess = true;
            this.loadingText =
              "Please wait, Redirecting you to complete your information.";
            this.verifyUser();
          } else {
            this.checkIsSecureAccess = false;
            await this.resendPassword();
          }
          this.is_valid_user = this.getDocumentPreviewData.is_valid_user;
          if (this.is_valid_user) {
            let format = "MM-DD-YYYY";
            this.expiration_date = moment(
              this.getDocumentPreviewData.expiration_data
            ).format(format);
          }
        }
        this.documentDataLoading = false;
      } catch (err) {
        this.documentDataLoading = false;
        console.log("getPreviewOfDocument", err);
      }
    },
    async resendPassword() {
      try {
        let params = {
          employee_document_id: this.$route?.params?.employee_document_id,
        };
        if (this.$route.query.workflow_id) {
          params.workflow_data_id = this.$route.query.workflow_id;
        }
        let data = await postAPICall("POST", "/verify/doc-pass", params);
        if (data && data.success) {
          this.$notify.success({
            title: "Success",
            message: "Password sent to email successfully",
          });
        }
      } catch (err) {
        console.log(err);
      }
    },
    async verifyUser() {
      try {
        console.log(this.verificationCode);
        this.documentDataLoading = true;
        let params = {
          user_id: this.$route.params.user_id,
          employee_document_id: this.$route.params.employee_document_id,
          password: this.verificationCode,
        };
        if (this.$route.query.workflow_id) {
          params.workflow_data_id = this.$route.query.workflow_id;
        }
        if (this.$route.query.initiated) {
          params.initiated = this.$route.query.initiated;
        }
        await this.$store.dispatch(
          "auth/verifyAuthorizedRepresentativePassword",
          params
        );
        if (this.getAuthenticationStatus) {
          this.$notify.success({
            title: "Success",
            message: "Verified successfully...",
          });
          let docId =
            this.getAuthenticationDetails &&
            this.getAuthenticationDetails.document_id
              ? this.getAuthenticationDetails.document_id
              : this.$route.params.employee_document_id;
          if (this.$route.query.workflow_id) {
            this.$router.push({
              name: "employee-documents-custom-document-pp",
              params: {
                user_id: this.$route.params.user_id,
                employee_document_id: docId,
                password: this.verificationCode,
              },
              query: {
                workflow_id: this.$route.query.workflow_id,
              },
            });
          } else {
            this.$router.push({
              name: "employee-documents-custom-document-pp",
              params: {
                user_id: this.$route.params.user_id,
                employee_document_id: docId,
                password: this.verificationCode,
              },
            });
          }
        }
        this.documentDataLoading = false;
      } catch (err) {
        this.documentDataLoading = false;
        console.log("verifyUser", err);
      }
    },
    resetStore() {
      this.$store.commit("auth/resetAuthData", null, { root: true });
      this.$store.commit(
        "configurableDocuments/resetConfigurableDocuments",
        null,
        { root: true }
      );
      this.$store.commit("employeeData/reset", null, { root: true });
      this.$store.commit("employeeDocuments/reset", null, { root: true });
      // this.$store.commit("employeeDocumentAuthorizedUsers/reset");
    },
  },
  beforeDestroy() {
    this.$store.commit("documents/setDocumentPreviewData", null, {
      root: true,
    });
    this.$store.commit(
      "configurableDocuments/setConfigurableDocumentPublishData",
      null,
      { root: true }
    );
    this.$store.commit(
      "configurableDocuments/setAuthenticateUserToFillDocumentStatus",
      null,
      { root: true }
    );
    this.$store.commit(
      "configurableDocuments/setAuthenticateUserToFillDocumentData",
      null,
      { root: true }
    );
    this.$store.commit(
      "configurableDocuments/setAuthenticateUserToFillDocumentErrors",
      null,
      { root: true }
    );
    this.$store.commit("contacts/setCheckContactDataWithEmail", null, {
      root: true,
    });
  },
};
</script>
<style lang="scss">
.el-dialog__body {
  /* test*/
  overflow-y: unset !important;
  overflow-x: unset !important;
}
</style>

<style lang="scss" scoped>
.document-corrected-container {
  font-family: "Inter", sans-serif;
  text-align: center;
  height: 630px;

  .corrected-heading {
    font-weight: 500;
    font-size: 22px;
    line-height: 39px;
    color: #212529;
  }

  .corrected-content {
    font-weight: 400;
    font-size: 18px;
    line-height: 39px;
    color: #303133;
  }

  .correct-content {
    font-weight: 400;
    font-size: 18px;
    line-height: 39px;
    color: #303133;
  }
}

.logo {
  margin-top: 10px;
  width: 150px;
  //   height: 40px !important;
}

.document-root {
  position: relative;
  height: calc(100vh - 90px);
  overflow: hidden;
  width: 950px;
  margin: 10px auto;

  .form-field-options {
    position: sticky;
    bottom: 0px;
    margin: 10px auto 0;
    padding: 5px 0px;
    background-color: #ffffff;
    border-top: 1px solid #efefef;
  }

  .each-stat {
    margin-right: 1em;
  }

  .field-stats-block {
    p {
      line-height: 40px;
    }

    .count {
      margin-right: 0.5em;
      background-color: #ffffff;
      height: 40px;
      width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    }
  }
}

.form-builder {
  width: 950px;
  max-height: 900px;
  overflow-y: auto;
  background-color: #ffffff;
  border: 1px solid #efefef;
  -webkit-box-shadow: 0 0px 10px 1px rgba(0, 0, 0, 0.03);
  box-shadow: 0 0px 10px 1px rgba(0, 0, 0, 0.03);
  border-radius: 10px;

  .form-image-holder {
    width: 100%;

    img {
      width: 100%;
    }
  }

  .form-fields-holder {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    .each-element img {
      width: 100%;
    }
  }
}

#drag-template-background {
  .img-fluid {
    height: auto !important;
  }

  .inner-navbar {
    // position: fixed;
    // top: 0;
    // left: 0;
    // right: 0;
    z-index: 100;
    background-color: #1b487e;
    border: 1px solid #1b487e;
    margin-bottom: 0 !important;

    & > .d-flex {
      gap: 10px;
      height: 60px;
      padding: 0 10px;
      align-items: center;

      .back-btn {
        height: inherit;
      }
    }

    .left-block {
      .el-input__inner {
        min-width: 250px;
        max-width: 300px;
        background: #fff;
        height: 47px;
      }
    }

    .expiry-date {
      border-radius: 20px;
      padding: 0px 10px;
      background: #f754a2;
      color: #ffffff;
      font-weight: 600;
      font-size: 0.91em;
      border-color: #f754a2;
    }

    .manage-recipients {
      padding: 0;
      background-color: transparent;
      border: none;

      .el-tag {
        font-size: 1em;
        border-radius: 20px;
        padding: 0px;
        line-height: 40px;
        height: 40px;
        width: 40px;
        margin-left: -0.35em;
        border: 1px solid #1b487e;
      }
    }
  }

  .document-preview-block {
    position: relative;

    // top: 62px;
    // display: grid;
    // justify-content: center;
    .activity-block {
      background-color: #ffffff;

      // border-left: 1px solid #efefef;
      // Tags
      .el-tabs {
        box-shadow: none;
        min-height: calc(100vh - 62px);
      }
    }

    td {
      padding-top: 0;
      vertical-align: top;

      &:nth-child(1) {
        width: 20%;
      }

      &:nth-child(2) {
        width: 80%;
      }
    }

    .inputs-list {
      background: #fff;
      padding: 15px;
      box-shadow: 0 1px 5px 0px rgba(0, 0, 0, 0.06),
        0 1px 0px rgba(0, 0, 0, 0.08);
      border-radius: 5px;

      .el-button {
        border: none;
        background: rgba(236, 245, 255, 0.7);

        &:hover {
          background: rgba(236, 245, 255, 1);
        }
      }

      li {
        &:not(:last-child) {
          margin-bottom: 15px;
        }
      }
    }
  }

  .form-image {
    max-width: 770px;
    min-height: 1000px;
    background-color: #e6dcdc;
  }

  .el-input__inner {
    display: inline !important;
  }

  .form-holder {
    width: 100%;
    position: relative;

    .el-input__inner,
    .el-textarea__inner {
      height: 100% !important;
      min-height: 30px !important;
      line-height: inherit !important;
    }

    input[type="checkbox"] {
      margin: 0 !important;
    }
  }

  .sample-form1 {
    position: relative;
    width: 100%;
    max-height: 91vh;
    overflow: scroll;
    border: 1px solid #ccc;
    margin-left: 20px;
    margin-bottom: 45px;

    ///
    .each-page {
      position: relative;
      width: 900px;
      display: block;
      height: 800px;
      overflow-y: auto;

      .form-image {
        height: 800px;
        position: absolute;
        width: 850px;
        top: 0;
        left: 0;
      }

      .form-fields {
        height: 800px;
        position: absolute;
        width: 850px;
        top: 0;
        left: 0;
        z-index: 100;
      }

      .page-image {
        width: 850px;
        margin: 0 auto;
      }
    }
  }

  .color-btn {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    margin: 10px;

    &.red {
      background-color: red;
    }

    &.black {
      background-color: black;
    }

    &.green {
      background-color: green;
    }
  }

  // Fields
  .form-fields-set {
    background-color: #ffffff;
    padding: 1.25em 1em;
    border-radius: 2px;
    -webkit-box-shadow: 0 0px 10px 1px rgba(0, 0, 0, 0.03);
    box-shadow: 0 0px 10px 1px rgba(0, 0, 0, 0.03);

    .el-form-item {
      margin-bottom: 0.5em;
    }
  }
}
</style>

<style lang="scss" scoped>
.view-configure-document {
  position: relative;
  padding-top: 0 !important;
  background-color: #f8f9fa;

  .error {
    color: red;
  }

  .primary {
    color: #1b487e;
  }

  .right-block {
    height: inherit;
    display: flex;
    align-items: center;
  }
}

.overlay-form {
  background-color: rgba(0, 0, 0, 0.95);
  position: absolute;
  top: 60px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;

  .log-form {
    max-width: 550px;
    padding: 3.5em;
    border-radius: 5px;
    border: 1px dashed #ffffff;

    .el-form-item__label {
      color: #ffffff;
    }

    .el-input__inner {
      padding: 0 1.5em;
      line-height: 3.5em;
      height: auto;
      font-size: 1.15em;
      border-color: #8692a6;
      letter-spacing: 0.075em;

      &:hover,
      &:focus {
        border-color: #2813e0;
        background-color: #ffffff;
      }
    }

    .el-button {
      padding: 0 1.5em;
      line-height: 3.35em;
      height: auto;
      letter-spacing: 0.5px;
    }
  }
}

.navbar-head {
  border-bottom: #cbd6e2 solid 1px;
  height: 69px;
  background-color: #1b487e;
}

.navbar-title {
  font-size: 18px;
  margin-top: 1%;
  margin-left: 2%;
  color: #677788;
  font-weight: 400;
}

.center-card {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.card {
  width: 550px;
  padding: 30px;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-top: 50px;
  top: -100px;
}

.card h3 {
  margin-bottom: 10px;
  height: 70px;
  width: 500px;
}

.btn-primary,
.btn-outline-secondary {
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px 20px;
}

.btn-primary {
  background-color: #1b487e;
}

.btn-outline-secondary {
  background-color: #ffffff;
}

@media only screen and (max-width: 1200px) {
  .card {
    width: 80%;
    padding: 20px;
    margin-top: 20px;
  }

  .card h3 {
    width: 100%;
  }
}

.el-button.btn-primary {
  font-size: 14px;
  padding: 8px 15px;
}

.country-selector__input {
  background-color: #eff3fa !important;
  height: 50px !important;
  -webkit-box-shadow: 0px 1px 2px #ddebfa !important;
  box-shadow: 0px 1px 2px #ddebfa !important;
  border: none !important;
  border-radius: 5px !important;
  font-size: 1.1em !important;
  letter-spacing: 0.5px !important;
}

.input-tel__input {
  background-color: #eff3fa !important;
  height: 50px !important;
  -webkit-box-shadow: 0px 1px 2px #ddebfa !important;
  box-shadow: 0px 1px 2px #ddebfa !important;
  border: none !important;
  border-radius: 5px !important;
  font-size: 1.1em !important;
  letter-spacing: 0.5px !important;
}
.asterikStyle {
  color: red;
  font-weight: bold;
}
</style>
